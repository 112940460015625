import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';
import { Validators, UntypedFormBuilder, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { NumberFilterType } from 'src/app/core/services/api-services';
import { FilterType } from 'src/app/core/common/filter-type';
import { Filter } from 'src/app/core/models/filter-paging.model';
import EnumHelper from 'src/app/core/helpers/enum.helper';

@Component({
  selector: 'app-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrls: ['./number-filter.component.scss']
})
export class NumberFilterComponent extends BaseFilterComponent<NumberFilterType> implements OnInit {
  filterForm = new FormGroup({
    filterType: new FormControl<NumberFilterType>(NumberFilterType.Equals, { validators: [Validators.required], nonNullable: true }),
  });

  numberBetweenForm = new FormGroup({
    value: new FormControl<number | null>(null, { validators: [Validators.required] }),
    value2: new FormControl<number | null>(null, { validators: [Validators.required] }),
  });

  singleNumberForm = new FormGroup({
    value: new FormControl<number | null>(null, { validators: [Validators.required] }),
    value2: new FormControl<null>(null),
  });

  filterOptions = this.getFilterOptions();
  currentFilter = new BehaviorSubject<NumberFilterType>(NumberFilterType.Equals);
  numberFilterBetween = NumberFilterType.Between;
  numberFilterType = NumberFilterType;
  filterType: FilterType = FilterType.Number;

  constructor()
  {
    super();
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.currentValue.next(this.initialValue);
    }
  }

  clearFilter(): void {
    this.currentValue.next(null);
    this.activeFilterField = this.currentValue.value !== null;
    this.filterForm.reset();
    this.currentFilter.next(this.filterTypeControl.value);
    this.numberBetweenForm.reset();
    this.singleNumberForm.reset();
  }

  initValue(value: Filter<NumberFilterType>): void {
    this.currentFilter.next(value.filterType);
    this.filterTypeControl.setValue(value.filterType);
    this.currentValue.next(value);
    this.activeFilterField = this.currentValue.value !== null;
    if (value.filterType === NumberFilterType.Between) {
      this.numberBetweenForm.markAllAsTouched();
      this.numberBetweenValueControl.setValue(value.values[0]);
      this.numberBetweenValue2Control.setValue(value.values[1]);
    }
    else {
      this.singleNumberForm.markAllAsTouched();
      this.numberSingleNumberControl.setValue(value.values[0]);
    }
  }

  getFilterOptions(): { value: number, translationKey: string }[] {
    const options: { value: number, translationKey: string }[] = [];

    Object.keys(NumberFilterType).filter((enumVal) => !isNaN(Number(enumVal))).forEach((enumVal: any) => {
      options.push({
        value: parseInt(enumVal, 10),
        translationKey: `Common.Filters.NumberFilters.${NumberFilterType[enumVal]}`
      });
    });

    return options;
  }

  onFilterChange($event: MatSelectChange): void {
    this.currentFilter.next($event.value as NumberFilterType);
  }

  onSubmit($event: any): void {
    if (this.currentFilter.value !== NumberFilterType.Between) {
      // Trigger form validation for untouched fields
      this.singleNumberForm.markAllAsTouched();

      if (this.singleNumberForm.valid) {
        const model: Filter<NumberFilterType> = {
          filterType: this.currentFilter.value,
          values: [this.numberSingleNumberControl.value, null] as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    } else {
      // Trigger form validation for untouched fields
      this.numberBetweenForm.markAllAsTouched();

      if (this.numberBetweenForm.valid) {
        const model: Filter<NumberFilterType> = {
          filterType: this.currentFilter.value,
          values: [this.numberBetweenValueControl.value, this.numberBetweenValue2Control.value] as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    }
  }

  onCancel(): void {
  }

  get numberSingleNumberControl(): FormControl {
    return this.singleNumberForm.get('value')! as FormControl;
  }

  get numberBetweenValueControl(): FormControl {
    return this.numberBetweenForm.get('value')! as FormControl;
  }

  get numberBetweenValue2Control(): FormControl {
    return this.numberBetweenForm.get('value2')! as FormControl;
  }

  get filterTypeControl(): FormControl {
    return this.filterForm.get('filterType')! as FormControl;
  }
}
