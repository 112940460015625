import { Component, ElementRef, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DirtyDialogComponent } from 'src/app/core/components/dirty-dialog.component';
import { CreateBookingModel, EventRoomResult, InteriorModel, RoomTypeModel } from 'src/app/core/services/api-services';
import { RoomTypeService } from 'src/app/core/services/api-services';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-event-room-search',
  templateUrl: './event-room-search.component.html',
  styleUrls: ['./event-room-search.component.scss']
})
export class EventRoomSearchComponent extends DirtyDialogComponent {
  dataSource: MatTableDataSource<EventRoomResult> | null = null;
  displayedColumns: string[] = [];
  locationControls = new Map<string, FormControl<string | null>>();
  roomTypeIdsControl = new FormControl<string[]>([]);

  eventRooms: EventRoomResult[] | null = [];
  nameAsc: boolean = true;
  seatsAsc: boolean = true;
  wheelchairs!: number;
  forCouncilMemberMeet: boolean = false;
  showRoomsDropdown: boolean = false;
  selectedRoomTypes: string[] | null;
  roomTypes: RoomTypeModel[] = [];

  constructor(protected readonly element: ElementRef,
    protected override readonly messageService: MessageService,
    private readonly roomTypeService: RoomTypeService,
    protected override readonly matDialogRef: MatDialogRef<EventRoomSearchComponent>,
    @Inject(MAT_DIALOG_DATA) data: { data: EventRoomResult[] | null, wheelchairs: number | null, forCouncilMemberMeet: boolean | null | undefined, showRoomsDropdown: boolean, selectedRoomTypes: string[] | null }) {
    super(messageService, matDialogRef);

    this.eventRooms = data?.data;
    this.dataSource = new MatTableDataSource<EventRoomResult>();
    this.dataSource.data = data?.data!;
    this.wheelchairs = data?.wheelchairs!;
    this.forCouncilMemberMeet = data?.forCouncilMemberMeet ?? false;
    this.showRoomsDropdown = data.showRoomsDropdown;
    this.selectedRoomTypes = data.selectedRoomTypes;

    if (this.forCouncilMemberMeet) {
      this.displayedColumns = ['name', 'seats', 'maxSeats', 'floor', 'building', 'conflicts', 'select'];
    } else {
      this.displayedColumns = ['name', 'seats', 'maxSeats', 'floor', 'building', 'interiors', 'location', 'select'];
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    
    if(this.showRoomsDropdown){
      this.roomTypeService.getAll()
        .unsubscribeOnDestroy(this)
        .subscribe(data => {
          this.roomTypes = data!
      });

      if(this.selectedRoomTypes !== null && this.selectedRoomTypes?.length > 0){
        this.roomTypeIdsControl.setValue(this.selectedRoomTypes);
        this.onRoomTypesChange(false);
      }
    }

    this.dataSource?.data.forEach(item => {
      if (item.requiresLocation) {
        this.locationControls.set(item.roomId, new FormControl<string | null>(null, Validators.required));
      }
    });
  }

  onSelect(item: EventRoomResult): void {
    const locationControl = this.locationControls.get(item.roomId);

    if (item.requiresLocation && locationControl) {
      locationControl.markAsTouched();

      if (!locationControl.valid) {
        return;
      }
    }

    if (item.conflicts) {
      const confirm = this.messageService.openConfirmLabel('Common.Messages.RoomHasConflictConfirmation', 'Common.Book');
      confirm
        .afterDismissed()
        .unsubscribeOnDestroy(this)
        .subscribe(v => {
          if (confirm.instance.isConfirmed) {
            this.matDialogRef.close({ roomId: item.roomId, roomLocation: locationControl?.value } as CreateBookingModel);
          }
        });
    } else {
      this.matDialogRef.close({ roomId: item.roomId, roomLocation: locationControl?.value } as CreateBookingModel);
    }
  }

  orderByName(): void {
    if (this.nameAsc) {
      let sortedList = this.dataSource?.data.sort((a, b) => b.roomName.localeCompare(a.roomName));
      this.dataSource!.data = sortedList!;
      this.seatsAsc = true;
      this.nameAsc = false;
    } else {
      let sortedList = this.dataSource?.data.sort((a, b) => a.roomName.localeCompare(b.roomName));
      this.dataSource!.data = sortedList!;
      this.seatsAsc = true;
      this.nameAsc = true;
    }
  }

  orderBySeats(): void {
    if (this.seatsAsc) {
      let sortedList = this.dataSource?.data.sort((a, b) => (a.seats > b.seats) ? -1 : 1);
      this.dataSource!.data = sortedList!;
      this.nameAsc = true;
      this.seatsAsc = false;
    } else {
      let sortedList = this.dataSource?.data.sort((a, b) => (a.seats < b.seats) ? -1 : 1);
      this.dataSource!.data = sortedList!;
      this.nameAsc = true;
      this.seatsAsc = true;
    }
  }

  getInteriorString(interiors: InteriorModel[]): string {
    return interiors.map(i => i.name).join(', ');
  }
  
  onRoomTypesChange(open: boolean): void {
    if (!open) {
      const roomTypes = this.roomTypeIdsControl.value;
      if(roomTypes !== null && roomTypes.length > 0){
        this.dataSource!.data = this.eventRooms?.filter(x => roomTypes.includes(x.roomTypeId))!;
      }
      else{
        this.dataSource!.data = this.eventRooms!;
      }
    }
  }
}
