import { BehaviorSubject } from 'rxjs';
import { Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import FormHelper from 'src/app/core/helpers/form.helper';
import { Filter } from 'src/app/core/models/filter-paging.model';
import { BoolFilterType, DateTimeFilterType, EnumFilterType, GuidFilterType, GuidListFilterType, NumberFilterType, StringFilterType } from 'src/app/core/services/api-services';
import { FilterType } from 'src/app/core/common/filter-type';

@Directive()
export abstract class BaseFilterComponent<TFilterType extends
  StringFilterType | NumberFilterType | DateTimeFilterType | BoolFilterType | GuidFilterType | EnumFilterType | GuidListFilterType> {

  formHelper = FormHelper;

  // Initial Input
  @Input() initialValue: Filter<TFilterType> | null = null;

  // PropertyName to Filter (usually the column-name of the filter)
  @Input() propertyName!: string;

  // Filter Active Class
  @HostBinding('class.header-filter-active') activeFilterField = false;

  // Output event where event needs to be binded
  @Output() filterChanged = new EventEmitter<{ filter: Filter<TFilterType> | null, filterType: FilterType, propertyName: string }>();

  // Output event where event can to be binded
  @Output() filterCleared = new EventEmitter<any>();

  // Last selected Filter
  currentValue = new BehaviorSubject<Filter<TFilterType> | null>(null);

  abstract filterType: FilterType;

  constructor() {
  }

  abstract clearFilter(): void;
  abstract initValue(value: Filter<TFilterType>): void;

  onClear(): void {
    this.currentValue.next(null);
    this.filterChanged.emit({ filter: null, filterType: this.filterType, propertyName: this.propertyName });
    this.activeFilterField = false;
    this.clearFilter();

    this.filterCleared.emit();
  }

  initFilterValue(value: Filter<TFilterType>): void {
    this.initialValue = value;
    this.currentValue.next(value);
    this.initValue(value);
  }
}
