import { API_BASE_URL } from './core/services/api-services';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import localeDECH from '@angular/common/locales/de-CH';
import localeITCH from '@angular/common/locales/it-CH';
import localeFRCH from '@angular/common/locales/fr-CH';
import localeENGB from '@angular/common/locales/en-GB';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppConfigService } from './core/services/app-config.service';
import { LanguageService } from './core/services/language.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { GlobalErrorHandler } from './core/common/global-error-handler';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { CustomTranslateHttpLoader } from './core/common/custom-translate-http-loader';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatedPaginatorIntl } from './core/common/translated-paginator-intl';
import { LocaleProvider } from './core/common/locale.provider';

// Use this default format for pipes etc.
// If other formats are required custom pipes have to be created: https://medium.com/dailyjs/dynamic-locales-in-angular-dd9a527ebe1f
registerLocaleData(localeDECH);
registerLocaleData(localeITCH);
registerLocaleData(localeFRCH);
registerLocaleData(localeENGB);

export const initFactory = (appConfigService: AppConfigService, languageService: LanguageService) => {
  // Resolve app-config first because languageservice depends on it
  return () => appConfigService.init()
    .then(value => languageService.init());
};

export function getBaseUrl(appConfigService: AppConfigService): string {
  return `${appConfigService.getConfig().apiUrl}`;
}

export function HttpLoaderFactory(injector: Injector) {
  return new CustomTranslateHttpLoader(injector);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [Injector],
      },
    }),
    RouterModule.forRoot(
      [],
    )

  ],
  exports: [MatExpansionModule, SharedModule],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initFactory, deps: [AppConfigService, LanguageService], multi: true },
    { provide: API_BASE_URL, useFactory: getBaseUrl, deps: [AppConfigService] },
    LocaleProvider,
    LanguageService,
    {
      provide: MatPaginatorIntl,
      useClass: TranslatedPaginatorIntl,
    },
    /*{
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new TranslatedPaginatorIntl(translateService).getPaginatorIntl()
    },*/
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
  }
}
