<div class="row pt-3">
  <div class="col-xl-3 col-12">
    <h1 mat-dialog-title>
      {{ 'Common.EventRoomSearch.Title' | translate }}
      <span *ngIf="wheelchairs"> - <span class="red-color">{{"Public.AdditionalDetails.Wheelchairs" | translate}}: {{wheelchairs}}</span></span>
    </h1>
  </div>
  <div class="col-xl-3 col-12 mt-2" *ngIf="showRoomsDropdown">
    <mat-form-field class="w-100">
      <mat-label>{{ 'Private.Facility.Rooms.Calendar.RoomType' | translate }}</mat-label>
      <mat-select [formControl]="roomTypeIdsControl" multiple (openedChange)="onRoomTypesChange($event)">
        <mat-option *ngFor="let roomType of roomTypes" [value]="roomType.id">
          {{roomType.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<mat-dialog-content>
  <div class="row">
    <div class="col">
      <div *ngIf="!dataSource?.data?.length" class="col-12">
        {{ 'Common.EventRoomSearch.NoRoomResults' | translate }}
      </div>
      <table mat-table class="grid-table" *ngIf="dataSource?.data?.length" [dataSource]="dataSource!">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span class="filter-header">
              {{ 'Common.Entities.Room.Name' | translate }}
              <button *ngIf="nameAsc" mat-icon-button (click)="orderByName()"><mat-icon>arrow_upward</mat-icon></button>
              <button *ngIf="!nameAsc" mat-icon-button (click)="orderByName()"><mat-icon>arrow_downward</mat-icon></button>
            </span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.roomName }}</td>
        </ng-container>

        <ng-container matColumnDef="seats">
          <th mat-header-cell *matHeaderCellDef>
            <span class="filter-header">
              {{ 'Common.Entities.Room.Seats' | translate }}
              <button *ngIf="seatsAsc" mat-icon-button (click)="orderBySeats()"><mat-icon>arrow_upward</mat-icon></button>
              <button *ngIf="!seatsAsc" mat-icon-button (click)="orderBySeats()"><mat-icon>arrow_downward</mat-icon></button>
            </span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.seats }}</td>
        </ng-container>

        <ng-container matColumnDef="maxSeats">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Common.Entities.Room.MaxSeats' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.maxSeats }}</td>
        </ng-container>

        <ng-container matColumnDef="floor">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Common.Floor' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.roomFloor }}</td>
        </ng-container>

        <ng-container matColumnDef="building">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Common.Building' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.roomBuilding }}</td>
        </ng-container>

        <ng-container matColumnDef="conflicts">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Common.EventRoomSearch.Conflicts' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="error-text" [matTooltip]="element.conflicts">{{ element.conflicts }}</td>
        </ng-container>

        <ng-container matColumnDef="interiors">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Common.Entities.Room.RoomInteriors' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" [matTooltip]="getInteriorString(element.roomInteriors)">{{
            getInteriorString(element.roomInteriors) }}</td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Common.Entities.Booking.RoomLocation' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="w-100" *ngIf="element.requiresLocation" style="margin-bottom: -1.25em">
              <input matInput type="text" [formControl]="locationControls.get(element.roomId)!">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button type="button" mat-raised-button [color]="'primary'" (click)="onSelect(element)">
              <mat-icon>check</mat-icon><span>{{ 'Common.ChooseShort' | translate }}</span>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="row">
    <div class="col-12">
      <button mat-raised-button (click)="onCancel()">
        {{ 'Common.Close' | translate}}
      </button>
    </div>
  </div>
</mat-dialog-actions>