import { Injectable } from '@angular/core';
import { AuthenticationService as ApiAuthenticationService } from './api-services';
import { BaseAuthenticationService } from './base-authentication.service';
import { AuthenticatedModel } from '../services/api-services';
import LocalStorageHelper from '../helpers/local-storage.helper';

@Injectable({
  providedIn: 'root'
})
export abstract class PrivateAuthenticationService extends BaseAuthenticationService {
  private static readonly authenticationKey: string = 'pd.private-authentication-key';

  private readonly lastTimeSystemMessagesReadKey = 'pd.last-time-system-messages-read';

  constructor(
    protected override readonly authorizationService: ApiAuthenticationService,
  ) {
    super(authorizationService, PrivateAuthenticationService.authenticationKey)
  }

  public override setLoginInfo(data: AuthenticatedModel | null): void {
    this.authenticatedModel = data;

    if (data) {
      this.setAuthentication(this.authenticatedModel);
      LocalStorageHelper.setItemFromObject<string>(BaseAuthenticationService.PrivateEmailStorageKey, data.email);
    }
    else {
      // remove the items if data was null or undefined
      this.setAuthentication(null);
      LocalStorageHelper.clear(BaseAuthenticationService.PublicEmailStorageKey);
    }
  }

  public getLastTimeSystemMessagesRead(): Date | null {
    let storageValue = localStorage.getItem(this.lastTimeSystemMessagesReadKey);
    if (storageValue) {
      return JSON.parse(storageValue);
    } else {
      return null
    }
  }

  public setLastTimeSystemMessagesRead(date: Date): void {
    localStorage.setItem(this.lastTimeSystemMessagesReadKey, JSON.stringify(date));
  }
}
