import { Validators, UntypedFormBuilder, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { MatSelectChange } from '@angular/material/select';
import { GuidListFilterType } from 'src/app/core/services/api-services';
import { FilterType } from 'src/app/core/common/filter-type';
import EnumHelper from 'src/app/core/helpers/enum.helper';
import { Filter, IdNameModel } from 'src/app/core/models/filter-paging.model';

@Component({
  selector: 'app-guid-list-filter',
  templateUrl: './guid-list-filter.component.html',
  styleUrls: ['./guid-list-filter.component.scss']
})
export class GuidListFilterComponent extends BaseFilterComponent<GuidListFilterType> implements OnInit {

  @Input() options: IdNameModel[] = [];
  @Input() filterSelectText: string | undefined;

  // StringFilterType
  guidFilterType = GuidListFilterType;

  guidContainsAllFilter = GuidListFilterType.ContainsAll;

  filterForm = new FormGroup({
    filterType: new FormControl<GuidListFilterType>(GuidListFilterType.Contains, { validators: [Validators.required], nonNullable: true }),
  });

  guidListForm = new FormGroup({
    value: new FormControl<string | null>(null, { validators: [Validators.required] }),
  });

  multipleGuidListForm = new FormGroup({
    value: new FormControl<string | null>(null, { validators: [Validators.required] }),
  });

  filterOptions = this.getFilterOptions();
  currentFilter = new BehaviorSubject<GuidListFilterType>(GuidListFilterType.Contains);
  filterType: FilterType = FilterType.GuidList;

  constructor() {
    super();
   }

  getFilterOptions(): { value: number, translationKey: string }[] {
    const options: { value: number, translationKey: string }[] = [];

    Object.keys(GuidListFilterType).filter((enumVal) => !isNaN(Number(enumVal))).forEach((enumVal: any) => {
      options.push({
        value: parseInt(enumVal, 10),
        translationKey: `Common.Filters.GuidListFilters.${GuidListFilterType[enumVal]}`
      });
    });

    return options;
  }

  onFilterChange($event: MatSelectChange): void {
    this.currentFilter.next($event.value as GuidListFilterType);
  }

  ngOnInit(): void {
    if (this.initialValue) {
    this.currentValue.next(this.initialValue);
    }
  }

  clearFilter(): void {
    this.currentValue.next(null);
    this.activeFilterField = this.currentValue.value !== null;
    this.guidListForm.reset();
    this.multipleGuidListForm.reset();
  }

  initValue(value: Filter<GuidListFilterType>): void {
    this.guidListForm.markAllAsTouched();
    this.currentFilter.next(value.filterType);

    if (value.filterType === 0) {
      this.guidControl.setValue(value.values[0]);
    } else if (value.filterType === 1) {
      this.multipleGuidControl.setValue(value.values);
    }

    this.filterTypeControl.setValue(value.filterType);
    this.currentValue.next(value);
    this.activeFilterField = this.currentValue.value !== null;
  }

  onSubmit($event: any): void {
    if (this.currentFilter.value !== GuidListFilterType.ContainsAll) {
      // Trigger form validation for untouched fields
      this.guidListForm.markAllAsTouched();

      if (this.guidListForm.valid) {
        const model: Filter<GuidListFilterType> = {
          filterType: this.currentFilter.value,
          values: [this.guidControl.value, null] as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    } else {
      // Trigger form validation for untouched fields
      this.multipleGuidListForm.markAllAsTouched();

      if (this.multipleGuidListForm.valid) {
        const model: Filter<GuidListFilterType> = {
          filterType: this.currentFilter.value,
          values: this.multipleGuidControl.value as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    }
  }

  onCancel(): void {
  }

  get guidControl(): FormControl {
    return this.guidListForm.get('value')! as FormControl;
  }

  get multipleGuidControl(): FormControl {
    return this.multipleGuidListForm.get('value')! as FormControl;
  }

  get filterTypeControl(): FormControl {
    return this.filterForm.get('filterType')! as FormControl;
  }

}
