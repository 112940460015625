import { BehaviorSubject } from 'rxjs';
import { Validators, UntypedFormBuilder, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { DateTimeFilterType } from 'src/app/core/services/api-services';
import { FilterType } from 'src/app/core/common/filter-type';
import { Filter } from 'src/app/core/models/filter-paging.model';
import EnumHelper from 'src/app/core/helpers/enum.helper';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-date-time-filter',
  templateUrl: './date-time-filter.component.html',
  styleUrls: ['./date-time-filter.component.scss']
})
export class DateTimeFilterComponent extends BaseFilterComponent<DateTimeFilterType> implements OnInit {
  filterForm = new FormGroup({
    filterType: new FormControl<DateTimeFilterType>(DateTimeFilterType.Equals, { validators: [Validators.required], nonNullable: true }),
  });

  dateBetweenForm = new FormGroup({
    value: new FormControl<string>('', { validators: [Validators.required] }),
    value2: new FormControl<string>('', { validators: [Validators.required] }),
  });

  singleDateForm = new FormGroup({
    value: new FormControl<string>('', { validators: [Validators.required] }),
    value2: new FormControl<null>(null, { validators: [] }),
  });

  filterOptions = this.getFilterOptions();
  currentFilter = new BehaviorSubject<DateTimeFilterType>(DateTimeFilterType.Equals);
  dateFilterBetween = DateTimeFilterType.Between;
  filterType: FilterType = FilterType.DateTime;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.currentValue.next(this.initialValue);
    }
  }

  clearFilter(): void {
    this.currentValue.next(null);
    this.currentFilter.next(DateTimeFilterType.Equals);
    this.activeFilterField = this.currentValue.value !== null;
    this.filterForm.reset();
    this.dateBetweenForm.reset();
    this.singleDateForm.reset();
  }

  initValue(value: Filter<DateTimeFilterType>): void {
    this.singleDateForm.markAllAsTouched();
    this.currentFilter.next(value.filterType);
    this.dateSingleDateControl.setValue(value.values[0]);
    this.filterTypeControl.setValue(value.filterType);
    this.currentValue.next(value);
    this.activeFilterField = this.currentValue.value !== null;
  }

  getFilterOptions(): { value: number, translationKey: string }[] {
    const options: { value: number, translationKey: string }[] = [];

    Object.keys(DateTimeFilterType).filter((enumVal) => !isNaN(Number(enumVal))).forEach((enumVal: any) => {
      options.push({
        value: parseInt(enumVal, 10),
        translationKey: `Common.Filters.DateFilters.${DateTimeFilterType[enumVal]}`
      });
    });

    return options;
  }

  onFilterChange($event: MatSelectChange): void {
    this.currentFilter.next($event.value as DateTimeFilterType);
  }

  onSubmit($event: any): void {
    if (this.currentFilter.value !== DateTimeFilterType.Between) {
      // Trigger form validation for untouched fields
      this.singleDateForm.markAllAsTouched();

      if (this.singleDateForm.valid) {
        const model: Filter<DateTimeFilterType> = {
          filterType: this.currentFilter.value,
          values: [this.dateSingleDateControl.value, null] as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    } else {
      // Trigger form validation for untouched fields
      this.dateBetweenForm.markAllAsTouched();

      if (this.dateBetweenForm.valid) {
        const model: Filter<DateTimeFilterType> = {
          filterType: this.currentFilter.value,
          values: [this.dateBetweenValueControl.value, this.dateBetweenValue2Control.value] as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    }
  }

  onCancel(): void {
  }


  get dateSingleDateControl(): FormControl {
    return this.singleDateForm.get('value')! as FormControl;
  }

  get dateBetweenValueControl(): FormControl {
    return this.dateBetweenForm.get('value')! as FormControl;
  }

  get dateBetweenValue2Control(): FormControl {
    return this.dateBetweenForm.get('value2')! as FormControl;
  }

  get filterTypeControl(): FormControl {
    return this.filterForm.get('filterType')! as FormControl;
  }

}
