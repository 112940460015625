import { Component, Input } from '@angular/core';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { LanguageService as ApiLanguageService } from 'src/app/core/services/api-services';
import { LanguageModel } from 'src/app/core/services/api-services';
import { LanguageService } from 'src/app/core/services/language.service';
import { BaseComponent } from 'src/app/core/components/base.component';
import { PrivateAuthenticationService } from 'src/app/core/services/private-authentication.service';
import { PublicAuthenticationService } from 'src/app/core/services/public-authentication.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent extends BaseComponent {

  languages: LanguageModel[] = [];
  @Input() public cssClass!: string;

  public currentLanguage$: ReplaySubject<LanguageModel | undefined> = new ReplaySubject<LanguageModel | undefined>(1);
  readonly authenticated: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly apiLanguageService: ApiLanguageService,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly privateAuthenticationService: PrivateAuthenticationService,
    private readonly publicAuthenticationService: PublicAuthenticationService
  ) {
    super();
    this.apiLanguageService.getAll(false).subscribe((langs) => {
      this.languages = langs ?? [];

      this.languageService.currentLanguageCode$
        .unsubscribeOnDestroy(this)
        .subscribe(languageCode => {
          const selectedLanguage = this.languages.find(l => l.languageCode == languageCode);

          this.currentLanguage$.next(selectedLanguage!);
        });
    });



    const splittedUrl = this.router.url.replace('https://', '').replace('http://', '').split('/');

    if (splittedUrl.length > 1 && splittedUrl[1] == 'private') {
      this.authenticated = this.privateAuthenticationService.isAuthenticated();
    } else {
      this.authenticated = this.publicAuthenticationService.isAuthenticated();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public changeLanguage(code: string): void {
    this.languageService.setLanguageByCode(code)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.location.reload();
      });
  }
}
