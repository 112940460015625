import { Injectable } from '@angular/core';
import { AuthenticationService as ApiAuthenticationService } from './api-services';
import { BaseAuthenticationService } from './base-authentication.service';
import LocalStorageHelper from '../helpers/local-storage.helper';
import { AuthenticatedModel } from '../services/api-services';

@Injectable({
  providedIn: 'root'
})
export abstract class PublicAuthenticationService extends BaseAuthenticationService {
  private static readonly authenticationKey: string = 'pd.public-authentication-key';

  constructor(
    protected override readonly authorizationService: ApiAuthenticationService,
  ) {
    super(authorizationService, PublicAuthenticationService.authenticationKey)
  }

  public override setLoginInfo(data: AuthenticatedModel | null): void {
    this.authenticatedModel = data;

    if (data) {
      this.setAuthentication(this.authenticatedModel);
      LocalStorageHelper.setItemFromObject<string>(BaseAuthenticationService.PublicEmailStorageKey, data.email);
    }
    else {
      // remove the items if data was null or undefined
      this.setAuthentication(null);
      LocalStorageHelper.clear(BaseAuthenticationService.PublicEmailStorageKey);
    }
  }

  public getRelation(): string | null {
    const token = this.getToken();

    if (token) {
      const decodedToken = this.decodeJwt(token);

      return decodedToken['Relation'];
    }

    return null;
  }
}
