import { AuthenticateByPhoneTokenModel, AuthenticatedModel, AuthenticateModel, RefreshTokenModel, Rights } from '../services/api-services';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, delay, finalize, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService as ApiAuthenticationService } from './api-services';
import LocalStorageHelper from '../helpers/local-storage.helper';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseAuthenticationService {
  protected static PrivateEmailStorageKey: string = 'pdPrivateLoggedInEmail';
  protected static PublicEmailStorageKey: string = 'pdPublicLoggedInEmail';
  private readonly currentUniqueNameKey = 'pd.currentUniqueName';
  private readonly isRefreshingTokenKey = 'pd.isRefreshingToken';

  protected authenticatedModel: AuthenticatedModel | null = null;
  public $authenticated = new ReplaySubject<AuthenticatedModel | null>(1);
  
  private decodedAccessToken: any | null = null;
  private trackedAccessToken: string | null = null;
  private readonly allowMultipleUsers = false;
  private uniqueName: string | null = null;
  private static email: string | null = null;

  constructor(
    protected readonly authorizationService: ApiAuthenticationService,
    protected readonly authenticationKey: string
  ) {
    this.authenticatedModel = LocalStorageHelper.getItemFromObject<AuthenticatedModel>(this.authenticationKey);

    this.uniqueName = LocalStorageHelper.getItemFromObject<string>(this.currentUniqueNameKey);

    this.setAuthentication(this.authenticatedModel);
  }

  public abstract setLoginInfo(data: AuthenticatedModel | null): void;

  public logout(): void {
    const refreshToken = this.getRefreshToken();

    if(refreshToken) {
      this.authorizationService.logout(refreshToken)
      .pipe(finalize(() => this.setLoginInfo(null)))
      .subscribe();
    }
    else {
      this.setLoginInfo(null);
    }
  }

  public setAuthentication(authentication: AuthenticatedModel | null): void {
    if (authentication && authentication.token) {
      this.decodedAccessToken = this.decodeJwt(authentication.token);

      BaseAuthenticationService.email = this.decodedAccessToken.email;

      if (this.allowMultipleUsers) {
        this.uniqueName = this.decodedAccessToken.unique_name;

        LocalStorageHelper.setItemFromObject<string | null>(this.currentUniqueNameKey, this.uniqueName);
      }

      LocalStorageHelper.setItemFromObject<AuthenticatedModel>(this.getUniqueStorageKey(this.authenticationKey), authentication);

      this.trackedAccessToken = authentication.token;
    }
    else {
      this.decodedAccessToken = null;

      LocalStorageHelper.clear(this.getUniqueStorageKey(this.authenticationKey));

      if (this.allowMultipleUsers) {
        LocalStorageHelper.clear(this.getUniqueStorageKey(this.currentUniqueNameKey));
        this.uniqueName = null;
      }

      BaseAuthenticationService.email = null;

      this.trackedAccessToken = null;
    }

    this.isRefreshingToken = false;
    this.$authenticated.next(authentication);
  }

  public setPersonId(personId: string) {
    if (this.authenticatedModel) {
      this.authenticatedModel.personId = personId;
    }
  }

  public static getPrivateEmail(): string | null {
    return LocalStorageHelper.getItemFromObject<string>(BaseAuthenticationService.PrivateEmailStorageKey);
  }

  public static getPublicEmail(): string | null {
    return LocalStorageHelper.getItemFromObject<string>(BaseAuthenticationService.PublicEmailStorageKey);
  }

  public getToken(): string | null {
    if (this.trackedAccessToken !== (this.getAuthentication()?.token ?? null)) {
      this.setAuthentication(this.getAuthentication());
    }

    if (this.allowMultipleUsers) {
      LocalStorageHelper.setItemFromObject<string | null>(this.currentUniqueNameKey, this.uniqueName);
    }

    return this.getAuthentication()?.token ?? null;
  }

  public getRefreshToken(): string | null {
    return this.getAuthentication()?.refreshToken ?? null;
  }

  public tokenExpired(): boolean {
    const token = this.getToken();

    if (!token) {
      return true;
    }

    const expiryDate = this.decodeJwt(token).exp;

    return Math.floor(new Date().getTime() / 1000) >= expiryDate;
  }

  protected decodeJwt(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }

  public getDisplayName(): string | null {
    return this.authenticatedModel?.displayName ?? null;
  }

  public getLastName(): string | null {
    return this.authenticatedModel?.lastname ?? null;
  }

  public getFirstName(): string | null {
    return this.authenticatedModel?.firstname ?? null;
  }

  public getEmail(): string | null {
    return this.authenticatedModel?.email ?? null;
  }

  public getPhoneNumber(): string | null {
    return this.authenticatedModel?.phoneNumber ?? null;
  }

  public getUserId(): string | null {
    return this.authenticatedModel?.userId ?? null;
  }

  public getRoles(): string[] | null {
    return this.authenticatedModel?.roleKeys ?? null;
  }

  public getRights(): Rights[] | null {
    return this.authenticatedModel?.rightKeys ?? null;
  }

  public hasRight(right: Rights): boolean {
    if (!this.authenticatedModel) {
      return false;
    }

    return this.authenticatedModel.rightKeys.includes(right);
  }

  public hasAnyRight(rights: Rights[]): boolean {
    if (!this.authenticatedModel) {
      return false;
    }

    return this.authenticatedModel.rightKeys.some(right => rights.includes(right));
  }

  public getEmailConfirmed(): boolean | null {
    return this.authenticatedModel?.emailConfirmed ?? null;
  }

  public getPersonId(): string | null {
    return this.authenticatedModel?.personId ?? null;
  }

  public isAuthenticated(): boolean {
    return !this.tokenExpired();
  }
  
  private getAuthentication(): AuthenticatedModel | null {
    return LocalStorageHelper.getItemFromObject<AuthenticatedModel>(this.getUniqueStorageKey(this.authenticationKey));
  };

  authenticate(loginName: string, password: string, token: string | undefined = undefined): Observable<AuthenticatedModel | null> {
    if (!token) {
      const authModel: AuthenticateModel = {
        loginName: loginName,
        password: password
      };
      if (authModel) {
        return this.authorizationService.authenticate(authModel)
          .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
            this.setLoginInfo(loggedInModel);
            return loggedInModel;
          }));
      } else {
        return of(null)
      }
    } else {
      const authModel: AuthenticateByPhoneTokenModel = {
        loginName: loginName,
        password: password,
        token: token
      };
      if (authModel) {
        return this.authorizationService.authenticateByPhoneToken(authModel)
          .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
            this.setLoginInfo(loggedInModel);
            return loggedInModel;
          }));
      } else {
        return of(null)
      }
    }

  }

  public authenticateByCode(code: string, relation: string): Observable<AuthenticatedModel | null> {
    return this.authorizationService.authenticateByCode(code, relation)
      .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
        this.setLoginInfo(loggedInModel);
        return loggedInModel;
      }));
  }

  refreshToken(refreshToken?: string | null): Observable<AuthenticatedModel | null> {
    if(this.isRefreshingToken) {
      // When using multiple tabs with the same access token, 
      // only one tab should refresh the token and consume the refresh token.
      // Therefor wait here until the token is refreshed and reuse the new tokens from the first tab.
      return this.checkForRefreshedToken({ count: 1, max: 10, delayMilliseconds: 300 });
    }

    if (!refreshToken) {
      refreshToken = this.getRefreshToken();
    }

    if (refreshToken) {
      this.isRefreshingToken = true;

      const refreshModel: RefreshTokenModel = {
        refreshToken: refreshToken
      };

      return this.authorizationService.refreshToken(refreshModel)
        .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
          this.setLoginInfo(loggedInModel);

          return loggedInModel;
        }),
        catchError((error, caught) => {
          this.setLoginInfo(null);

          return of(null);
        }),
        finalize(() => this.isRefreshingToken = false));
    }
    else {
      return of(null)
    }
  }
  
  private getUniqueStorageKey(key: string): string {
    if (this.allowMultipleUsers && this.uniqueName) {
      return `${key}_${this.uniqueName}`;
    }

    return key;
  }
  
  private checkForRefreshedToken(counter: { count: number, max: number, delayMilliseconds: number }) : Observable<AuthenticatedModel | null> {
    if(!this.isRefreshingToken) {
      return of(LocalStorageHelper.getItemFromObject<AuthenticatedModel>(this.getUniqueStorageKey(this.authenticationKey)));
    }
    else if(counter.count < counter.max) {
      const totalDelayMilliseconds = counter.delayMilliseconds * counter.count;
      counter.count = counter.count + 1;
      return of(null)
        .pipe(delay(totalDelayMilliseconds))
        .pipe(switchMap(() => this.checkForRefreshedToken(counter)));
    }

    return of(null);
  }
  
  private get isRefreshingToken(): boolean {
    return LocalStorageHelper.getItemFromObject<boolean>(this.getUniqueStorageKey(this.isRefreshingTokenKey)) ?? false;
  }

  private set isRefreshingToken(value: boolean) {
    const key = this.getUniqueStorageKey(this.isRefreshingTokenKey);

    if (!value) {
      LocalStorageHelper.clear(key);
      return;
    }

    LocalStorageHelper.setItemFromObject<boolean>(key, value);
  }
}