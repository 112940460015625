import { Validators, UntypedFormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { Observable, of } from 'rxjs';
import { FilterType } from 'src/app/core/common/filter-type';
import { BoolFilterType } from 'src/app/core/services/api-services';
import { Filter } from 'src/app/core/models/filter-paging.model';

@Component({
  selector: 'app-bool-filter',
  templateUrl: './bool-filter.component.html',
  styleUrls: ['./bool-filter.component.scss']
})
export class BoolFilterComponent extends BaseFilterComponent<BoolFilterType> implements OnInit {

  // Initial Input
  @Input() labelTrue: string = '';
  @Input() labelFalse: string = '';
  @Input() useLabels = false;
  filterType: FilterType = FilterType.Bool;

  @Input() filterSelectText: string | undefined;

  boolForm = new FormGroup({
    value: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
  });

  constructor()
  {
    super();
  }


  ngOnInit(): void {
    if (this.initialValue) {
      this.currentValue.next(this.initialValue);
    }
  }

  clearFilter(): void {
    this.currentValue.next(null);
    this.activeFilterField = this.currentValue.value !== null;
    this.boolForm.reset();
  }

  initValue(value: Filter<BoolFilterType>): void {
    this.boolForm.markAllAsTouched();
    this.boolControl.setValue(value.values[0]);
    this.activeFilterField = this.currentValue.value !== null;
  }

  onSubmit($event: any): void {

    // Trigger form validation for untouched fields
    this.boolForm.markAllAsTouched();

    if (this.boolForm.valid) {
      const model: Filter<BoolFilterType> = {
        filterType: BoolFilterType.Equals,
        values: [this.boolControl.value, null] as any[],
        name: this.propertyName
      };

      this.currentValue.next(model);
      this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
      this.activeFilterField = true;
    } else {
      $event.stopPropagation();
    }
  }

  onCancel(): void {
  }

  get boolControl(): FormControl {
    return this.boolForm.get('value')! as FormControl;
  }
}
